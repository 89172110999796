@import '@angular/material/prebuilt-themes/indigo-pink.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.25px;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
    color: #777;
    transition: 0.2s;
}

a:hover {
    color: black;
}

.all-caps {
    text-transform: uppercase;
}

.center {
    text-align: center;
}

.routeContainer {
    position: relative;
    margin-top: 72px;
}

.routeContainer > * {
    display: block;
    margin: 32px 0;
}

/* Header Bar */

#header-bar {
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
    padding: 16px;
    color: #777;
    transition: 0.2s;
    font-size: 18px;
    display: flex;
    z-index: 100;
}

#header-bar > div a,
#header-bar > div a:visited {
    padding: 16px;
    font-size: 14px;
    font-family: 'Montserrat', 'Arial', sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

#header-bar #header-menu a.button {
    margin: 0;
    padding: 8px 16px;
}

/* Hero */

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo span {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.15em;
    line-height: 24px;
    color: #333;
}

#hero-section {
    /* background: rgb(34,193,195); */
    /* background: linear-gradient(0deg, rgba(34,193,195,.8) 0%, rgba(253,187,45,.5) 100%); */
    /* background-image: url(/a/images/background.jpg); */
    background-image: url(/a/images/hero-cover.jpg);
    background-color: #19a9e5;
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    text-align: center;
    color: white;
}

.cta-block {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

@media screen and (max-width: 480px) {
    .cta-block {
        width: 100%;
        flex-direction: column;
    }
}

.cta-block a:not(:last-child),
.cta-block button:not(:last-child) {
    margin-right: 8px;
}

.cta-block a,
.cta-block button {
    margin: 4px;
}

.event-hero-info {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 480px) {
    .event-hero-info {
        flex-direction: column;
    }
}

.event-hero-info a {
 color: #222;
}

.event-hero-info a:not(:last-child) {
    margin-right: 32px;
}

.icon-text-link {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon-text-link svg,
.icon-text-link img {
    margin-right: 8px;
}

/* Layout */

section {
    text-align: center;
    padding: 0 32px 64px;
    color: #333;
}

section.section-background {
    background-color: #19a9e5;
    color: white;
}
section.section-background h2 {
    color:white;
}

#home {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#home section {
    padding: 32px 48px;
    width: 100%;
    margin: auto;
    text-align: left;
}

#home section > div:first-child {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

#home section:last-child {
    padding-bottom: 40px;
}

#home section:not(:last-child) {
    border-bottom: 1px solid #d4d2d2;
}

#home section h2 {
    text-align: center;
}

#home #map img {
    width: 768fpx;
    height: auto;
    border-radius: 8px;
}



.split-column {
    display: flex;
    flex-direction: row;
    align-items: center;
}



.split-column > * {
    width: 50%;
}


#what-to-expect img {
    width: 340px;
    height: auto;
    margin-right: 16px;
}

   

.flex {
    display: flex;
}

/* Page Header Bannner */

header.page-header-banner {
    /* Replace later with hero image or background that matches style */
    /* background-color: #19a9e5;
    color: white; */
    padding: 18px 0;
    text-align: center;
}

/* Headers */

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: 36px;
    margin: 18px 0;
    font-weight: bold;
    letter-spacing: 1px;
}

h2 {
    font-size: 28px;
    margin: 18px 0;
    font-weight: bold;
    letter-spacing: 1px;
}

h3 {
    font-size: 20px;
    margin: 18px 0;
    font-weight: bold;
    letter-spacing: 1px;
}

h4 {
    font-size: 18px;
    margin: 8px 0;
    font-weight: bold;
    letter-spacing: 1px;
    color: #333
}

section h2 {
    text-transform: uppercase;
    color: #19a9e5;
}

/* section h2:after {
    margin: 17px auto 0;
    width: 140px;
    height: 1px;
    background: #19a9e5;
    content: '';
    display: block;
} */

section .callout {
    margin: 32px 0;
    font-size: 32px;
    color: #333;
    font-family: 'Montserrat', sans-serif;
    border-left: 5px solid #0fa3b1;
    padding-left: 24px;
}

section p {
    width: 100%;
    max-width: 945px;
    margin: 24px auto;
}


/* Footer */

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #19a9e5;
    padding: 32px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: white;
}

footer section {
    padding: 18px;
}

footer a {
    color: white;
    opacity: 0.8;
}

footer a:hover {
    color: #137097;
}

footer .blocks {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

footer .blocks h5 {
    font-size: 16px;
    line-height: 1.5em;
}

footer .blocks > div {
    margin: 18px 32px;
}

footer .blocks a {
    display: block;
}

/* Buttons */

.button, button {
    font-size: 14px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: bold;
    background-color: #2baadf;
    color: white;
    margin: 16px auto;
    padding: 8px 16px;
    display: inline-block;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.button:hover,
.cta:hover {
    opacity: 0.8;
}

a.button,
a.button:visited {
    color: white;
}

button.button.warn {
    background-color: #E93151;
}

.button-container > * {
    margin-right: 1rem;
}

/* Call to Action */

.cta {
    padding: 8px 22px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 25px;
    color: #fff;
    background: #19a9e5;
    font-size: 18px;
    font-family: Montserrat, sans-serif;
}
.cta-unselected {
    color: rgb(119, 119, 119);
    background: none;
}

.cta.cta-unselected:active,
.cta.cta-unselected:focus,
.cta.cta-unselected:hover {
    color: rgb(119, 119, 119);
}

.cta:active,
.cta:focus,
.cta:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1696CB;
}

.cta-stroke,
.cta-stroke:focus,
.cta-stroke:hover {
    color: #19a9e5;
}

.cta-transparent {
    border: 2px solid rgba(255, 255, 255, 0);
    background-color: rgba(34, 34, 34, 0.14);
    background-clip: padding-box;
}

.cta-transparent:hover {
    border: 2px solid #fff;
    background-color: rgba(34, 34, 34, 0.05);
}

/* Input with Button */

.input-with-button {
    display: flex;
    justify-content: center;
}

.input-with-button input {
    height: 40px;
    width: 200px;
    padding: 8px 16px;
    border-radius: 4px 0 0 4px;
    border: 1px solid lightgray;
}

.input-with-button button {
    background-color: #19a9e5;
    color: white;
    height: 40px;
    width: 100px;
    border-radius: 0 4px 4px 0;
    border-left: none;
    border-right: 1px solid #19a9e5;
    border-top: 1px solid #19a9e5;
    border-bottom: 1px solid #19a9e5;
    margin: 0;
    cursor: pointer;
}

/* Sponsors */

#home-section-sponsors h3 {
    text-transform: uppercase;
}

.sponsor-bar {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.sponsor-bar:last-child {
    margin-bottom: 0;
}

.sponsor-bar * {
    margin: 18px 32px;
    max-height: 100px;
}

/* FAQ Table */
.faq-table {
    align-items: baseline;
}

.faq-table ul {
    list-style: none;
    margin-right: 1rem;
}

.faq-table ul li {
    font-weight: bold;
    font-size: 16px;
    padding: 24px 0;
    cursor: pointer;
}

.faq-table ul li:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

/* Speakers */

.speaker-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.speaker-card {
    display: flex;
    align-items: center;
    height: 8em;
    width: 325px;
    margin: 3px;
    border-radius: 2px;
    border: 1px solid #ccc;
    padding: 16px;
}

.speaker-card .thumb {
    border: 1px solid #ccc;
    border-radius: 50%;
    background-size: cover;
    width: 70px;
    height: 70px;
}

.speaker-content {
    flex-grow: 1;
}

/* Schedule */

.schedule-timeslot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.schedule-announcement {
    color: rgb(119, 119, 119);
}

.bigschedulelist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    height: 13.2em;
    width: 325px;
    margin: 3px;
    border-radius: 2px;
    background-color: #f6f6f6;
}

.bigschedulelist h2 {
    font-size: 1em;
    font-weight: bold;
    text-transform: none;
    color: #333;
    margin-top: 5px;
}

.timeslot-block h2:after {
    margin-bottom: 17px;
}

.bigschedulelist p {
    margin: 3px auto;
}

.bigschedulelist h2:after {
    margin-top: 5px;
    margin-bottom: 5px;
}

.bigsschduledeets {
    opacity: 1;
    height: 100%;
    background: rgba(255, 255, 255, 0.25);
}

.session-content {
    padding: 5px;
}
.schedule a {
    color: white;
}
.schedule .session {
    color: white;
    padding: 5px;
}
.schedule tr td:first-child,
.schedule tr:first-child td,
.schedule .time {
    background-color: #eee;
    color: black;
    font-family: Montserrat, sans-serif;
    padding: 5px;
    vertical-align: top;
}

/* Colors choesn by GhapGPT. || https://chat.openai.com/share/17b6363e-9a11-4aaa-9b2c-fbf1e8c20e59
.... bard.google.com was aweful || https://g.co/bard/share/4419a089dd04
 */
.schedule .Android {
    /* background-color: rgba(123, 192, 67, 1); */
    background-color: #a4c639 ;
}
.schedule .Web {
    /* background-color: #ffbf00; */
    background-color: #3498db ;
}
.schedule .Design {
    background-color: #e74c3c ;
}
.schedule .Cloud {
    /* background-color: #ee4035; */
    background-color: #3498db ;
}
.schedule .AI {
    /* background-color: #ee35ee; */
    background-color: #ff7675 ;
}
.schedule .IoT {
    background-color: #6380ea;
}
.schedule .General {
    background-color: darkgray;
}
.schedule .all {
    background-color: #656f78;
}
.schedule .Keynote {
    /* background-color: #19a9e5; */
    background-color: #f39c12 ;
}

.schedule .lunch div {
    color: black;
}

.schedule.small .session,
.schedule.small .time {
    border-bottom: 1px solid white;
    display: block;
}
.schedule .session > div {
    padding: 3px;
}
.schedule.small .time {
    margin-top: 16px;
    padding: 5px;
}

.schedule .session .title {
    font-size: 1.2em;
}

.session-details {
    display: flex;
    max-width: 800px;
    margin: 0px auto;
}
.session-details-right {
    border-left: 5px solid #e5e5e5;
    margin-left: 50px;
    padding-left: 50px;
    margin-top: 16px;
}

/* Speaker CFP Page */
#speaker-cfp section {
    margin: auto;
    text-align: left;
}

.small {
    display: none;
}


/* Admin Pages */
/* Admin CFP */

.cfp-block {
    margin: 16px 0;
}

.cfp-block table {
    width: 100%;
}

.cfp-block table td {
    height: 32px;
    vertical-align: top;
}

.cfp-block table tr td:first-child {
    white-space: nowrap;
}

.cfp-block table tr td:not(:first-child) {
    padding-left: 16px;
}

/* Small Screens */
@media screen and (max-width: 480px) {
    #home #map img {
        width: 100%;
        height: auto;
    }
    .split-column {
        flex-direction: column;
    }
    .split-column > * {
        width: 100%;
        height: auto;
    }
    #what-to-expect img{
        width: 100%;
        height: auto;
        margin: 16px 0;
    }
}
/* Responsive - Medium  */
@media (max-width: 600px) {
    body {
        padding-top: 0;
    }

    .routeContainer {
        margin-top: 0;
    }

    .routeContainer > * {
        margin: 0;
    }

    #header-bar {
        flex-direction: column;
        position: relative;
    }

    #header-bar #header-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    #header-bar #header-menu a.button {
        display: flex;
        align-items: center;
        padding: 8px;
    }

    #header-bar #header-menu a {
        padding: 16px 8px;
    }

    #home section {
        padding: 18px 32px;
    }

    .session-details {
        flex-direction: column-reverse;
    }

    .session-details-right {
        margin: 0;
        padding: 0;
        border-left: 0px;
    }

    user-feedback {
        text-align: center;
    }

    footer .blocks {
        justify-content: center;
        text-align: center;
    }
}

/* Responsive - Medium Large Screens */
@media (max-width: 1024px) {
    .small {
        display: block;
    }
    .big {
        display: none;
    }
}

